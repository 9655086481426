<template>
  <div class="home">
    <ProgramModal
      v-if="vytvoritProgram || upravitProgram"
      :edit="upravitProgram"
      :id="upravitId"
      v-on:close="upravitProgram = false; vytvoritProgram = false;"
      :historicky="true"
    />

    <Settings v-if="openSettings" v-on:close="closeSettingsWindow()"  />

    <div
      class="blur-content-animation"
      :class="{'blur-content': this.vytvoritProgram || this.upravitProgram || this.smazatProgram || this.openSettings}"
    >

      <HeaderUser v-on:openSettings="openSettings = true;" />
      <div class="program_box">
        <Program
          v-for="program in programy_V"
          :key="program.id"
          :id="program.id_program"
          :nazev="program.nazev"
          :status="program.status"
          :datum_zalozeni="program.datum_zalozeni"
          :cena="program.cena"
          :veda="program.veda"
          :oblast="program.oblast"
          :oblast_name="program.oblast_name"
          :nazev_statusu="program.nazev_statusu"
          :nazev_veda="program.nazev_veda"
          :historicky="true"
          @upravit="upravit(program.id_program)"
        />
      </div>

    </div>
  </div>
</template>

<script>
import HeaderUser from "./components/HeaderUser.vue";
import Program from "./components/Program.vue";
import Settings from "./components/Settings.vue";
import ProgramModal from "./components/ProgramModal.vue";

export default {
  name: "Historicke_programy",
  components: {
    HeaderUser,
    Program,
    Settings,
    ProgramModal,
  },

  data() {
    return {
      id: "",
      nazev: null,
      showModal: null,
      programy_V: [],
      vytvoritProgram: false,
      upravitProgram: false,
      upravitId: 0,
      smazatId: false,
      smazatProgram: false,
      openSettings: false
    };
  },

  methods: {
    
    upravit(id) {
      this.upravitProgram = true;
      this.upravitId = id;
    },
    deleteProgramModal(id) {
      this.smazatProgram = true;
      this.smazatId = id;
      window.console.log(this.smazatId);
    },
    zavriModal() {
      setTimeout(
        function() {
          this.upravitProgram = false;
          this.vytvoritProgram = false;
          this.upravitId = false;
        }.bind(this),
        15
      );
    },
    closeSettingsWindow() {
      this.openSettings = false;
    }
  },

  mounted() {
    this.$gtag.pageview({
      page_path: "/historicke"
    });

    this.$http.get("http://localhost/fis_pokr_master/api/program/all_old/" + this.id).then(response => {
      this.programy_V = response.data;
    });
  }

  /*
    mounted() {
        this.getProgramy_old();
    }
*/
};
</script>
